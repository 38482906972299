<template>
  <Layout>
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title-box">
          <h3 class="">Edição de conteúdo</h3>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="float-right d-none d-md-block">
          <router-link
            :to="{ name: 'manageQuestions' }"
            class="btn btn-primary"
          >
            <i class="mdi mdi-view-list"></i>
            Lista de conteúdos
          </router-link>
        </div>
      </div>
    </div>
    <div class="card mb-4">
      <div class="card-body">
        <form @submit.prevent="onSubmit">
          <div class="row">
            <div class="col-md-6 col-12">
              <b-form-group
                label="Informativo de jurisprudência"
                label-for="discipline"
              >
                <b-form-checkbox switch class="mr-n2" v-model="form.isReport">
                </b-form-checkbox>
              </b-form-group>
            </div>

            <template v-if="!form.isReport">
              <div class="col-md-6 col-12">
                <b-form-group label="Ano" label-for="year">
                  <date-picker
                    id="year"
                    type="year"
                    v-model="form.year"
                    lang="pt-br"
                    confirm
                    placeholder=""
                    required
                  ></date-picker>
                </b-form-group>
              </div>
              <div class="col-md-6 col-12">
                <b-form-group label="Banca" label-for="jury">
                  <b-form-input
                    id="jury"
                    type="text"
                    v-model="form.jury"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-6 col-12">
                <b-form-group label="Órgão" label-for="institute">
                  <b-form-input
                    id="institute"
                    type="text"
                    v-model="form.institute"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-6 col-12">
                <b-form-group label="Cargo" label-for="role">
                  <b-form-input
                    id="role"
                    type="text"
                    v-model="form.role"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-6 col-12">
                <b-form-group label="Disciplina" label-for="discipline">
                  <multiselect
                    v-model="form.discipline"
                    tag-placeholder="Adicionar nova disciplina"
                    placeholder="Pesquise uma disciplina"
                    selectLabel=""
                    :taggable="true"
                    @tag="opt => {
                      disciplines.push(opt)
                      form.discipline = opt
                    }"
                    :options="disciplines"
                    selected-label="Selecionado"
                    deselect-label="Pressione Enter para remover"
                  ></multiselect>
                </b-form-group>
              </div>
            </template>

            <template v-else>
              <div class="col-md-6 col-12">
                <b-form-group label="Número" label-for="number">
                  <b-form-input
                    id="number"
                    type="number"
                    v-model="form.number"
                    lang="pt-br"
                    confirm
                    placeholder="Número"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-6 col-12">
                <b-form-group label="Título" label-for="title">
                  <b-form-input
                    id="title"
                    type="text"
                    v-model="form.title"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-6 col-12">
                <b-form-group label="Origem" label-for="souce">
                  <b-form-select
                    v-model="form.source"
                    :options="[
                      'Supremo Tribunal Federal (STF)',
                      'Superior Tribunal de Justiça (STJ)',
                    ]"
                  ></b-form-select>
                </b-form-group>
              </div>
            </template>
          </div>

          <b-form-group label="Enunciado" label-for="question">
            <QuillEditor
              id="question"
              v-model="form.question"
              :options="{ placeholder: 'Insira o texto aqui...' }"
              required
            ></QuillEditor>
          </b-form-group>

          <template v-if="!form.isReport">
            <h6 class="mb-3">Alternativas</h6>

            <b-input-group prepend="A" class="my-3">
              <b-form-textarea
                placeholder="Escreva aqui a alternativa A "
                v-model="form.answerA"
                required
                rows="4"
                max-rows="12"
              ></b-form-textarea>
            </b-input-group>
            <b-input-group prepend="B" class="my-3">
              <b-form-textarea
                placeholder="Escreva aqui a alternativa B "
                v-model="form.answerB"
                required
                rows="4"
                max-rows="12"
              ></b-form-textarea>
            </b-input-group>
            <b-input-group prepend="C" class="my-3">
              <b-form-textarea
                placeholder="Escreva aqui a alternativa C (Opcional)"
                v-model="form.answerC"
                rows="4"
                max-rows="12"
              ></b-form-textarea>
            </b-input-group>
            <b-input-group prepend="D" class="my-3">
              <b-form-textarea
                placeholder="Escreva aqui a alternativa D (Opcional)"
                v-model="form.answerD"
                rows="4"
                max-rows="12"
              ></b-form-textarea>
            </b-input-group>
            <b-input-group prepend="E" class="my-3">
              <b-form-textarea
                placeholder="Escreva aqui a alternativa E (Opcional)"
                v-model="form.answerE"
                rows="4"
                max-rows="12"
              ></b-form-textarea>
            </b-input-group>

            <div class="form-group">
              <label>Gabarito</label>
              <select v-model="form.realAnswer" class="form-control">
                <option>Selecione a alternativa correta</option>
                <option value="a">A</option>
                <option value="b">B</option>
                <option v-if="form.answerC !== ''" value="c">C</option>
                <option v-if="form.answerD !== ''" value="d">D</option>
                <option v-if="form.answerE !== ''" value="e">E</option>
              </select>
            </div>

            <div class="form-group">
              <label class="">Interesses</label>
              <multiselect
                v-model="form.interests"
                tag-placeholder="Adicionar novo interesse"
                placeholder="Pesquise um interesse"
                label="name"
                track-by="id"
                selectLabel=""
                :options="interests"
                :multiple="true"
                selected-label="Selecionado"
                deselect-label="Pressione Enter para remover"
              ></multiselect>
            </div>

            <div class="form-group">
              <label class="">Assuntos</label>
              <multiselect
                v-model="form.subjects"
                tag-placeholder="Adicionar novo assunto"
                placeholder="Pesquise um assunto"
                label="name"
                track-by="id"
                selectLabel=""
                :options="subjects"
                :multiple="true"
                :taggable="true"
                @tag="addSubject"
                selected-label="Selecionado"
                deselect-label="Pressione Enter para remover"
              ></multiselect>
            </div>
          </template>

          <div v-if="form.reports.length" class="form-group">
            <label for="errorsReport">
              Problemas reportados (apague e salve ao resolver)
            </label>
            <b-form-textarea v-model="form.reports" :rows="1" :max-rows="6" />
          </div>

          <div class="custom-control custom-checkbox mb-4">
            <input
              id="ispublished"
              type="checkbox"
              class="custom-control-input"
              v-model="form.isPublished"
            />
            <label class="custom-control-label" for="ispublished">
              Publicar questão
            </label>
          </div>

          <div class="my-4">
            <router-link
              :to="{ name: 'manageQuestions' }"
              class="btn btn-secondary"
            >
              <i class="mdi mdi-eraser"></i>
              Cancelar
            </router-link>
            <button type="submit" class="btn btn-success ml-3">
              <i class="mdi mdi-check-bold"></i>
              Editar conteúdo
            </button>
          </div>
        </form>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../layouts/main";
import appConfig from "@/app.config";

import * as api from "@/api";

import { quillEditor } from "vue-quill-editor";

import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";

const defaultForm = () => ({
  year: new Date(),
  jury: "",
  institute: "",
  role: "",
  discipline: "",
  question: "",
  answerA: "",
  answerB: "",
  answerC: "",
  answerD: "",
  answerE: "",
  realAnswer: "a",
  isPublished: "",
  number: 0,
  title: "",
  source: "",
  reports: "",
  interests: [],
  subjects: [],
});

export default {
  page: {
    title: "Gerenciamento de Conteúdo",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    Multiselect,
    DatePicker,
    QuillEditor: quillEditor,
  },
  data() {
    return {
      form: defaultForm(),

      interests: [],
      subjects: [],
      disciplines: [],
    };
  },

  created() {
    this.loadQuestion();
    api.getAllInterests().then((interests) => {
      this.interests = interests;
    });
    api.getAllDisciplines().then((disciplines) => {
      this.disciplines = disciplines.map(d => d.discipline);
    });
    this.loadSubjects();
  },

  methods: {
    reset() {
      this.form = defaultForm();
    },

    async loadSubjects() {
      await api.getAllSubjects().then((subjects) => {
        this.subjects = subjects;
      });
    },

    loadQuestion() {
      api
        .getQuestionById(this.$route.params.id, { adminMode: true })
        .then((question) => {
          this.form = {
            ...question,
            year: question.year
              ? new Date(new Date().setFullYear(question.year))
              : 2000,
          };
        })
        .catch(() => {
          this.$swal("Opa", "Falha ao carregar conteúdo!", "error");
        });
    },

    onSubmit() {
      const form = {
        ...this.form,
        year: this.form.year ? this.form.year.getUTCFullYear() : undefined,
        interests: this.form.interests.map((i) => i.id),
        subjects: this.form.subjects.map((i) => i.id),
      };
      api
        .updateQuestion(form.id, form)
        .then(async (updated) => {
          await this.loadSubjects();
          this.form.subjects = this.subjects.filter((s) =>
            updated.subjects.some((other) => other.id === s.id)
          );
          this.$swal("Sucesso", "Conteúdo editado com sucesso", "success");
        })
        .catch(() => {
          this.$swal("Opa", "Algo deu errado", "error");
        });
    },

    addSubject(name) {
      const item = { name, id: name };
      this.subjects = this.subjects.concat(item);
      this.form.subjects.push(item);
    },
  },
};
</script>
